<template>
    <div class="instructor-card-item">
        <img
            :src="instructor.imageUrl"
            :alt="instructor.name"
            class="instructor-image"
        >

        <h4 class="name z-h6">
            {{ instructor.name }}
        </h4>

        <p class="description z-b4 f-inter">
            {{ instructor.description }}
        </p>
    </div>
</template>

<script lang="ts" setup>
import {PropType} from "vue";

export interface Instructor {
    imageUrl: string,
    name: string,
    description: string,
}

const props = defineProps({
    instructor: {
        type: Object as PropType<Instructor>,
        required: true
    },
})
</script>

<style lang="scss">
.instructor-card-item {
    max-width: 13.6rem;

    .instructor-image {
        width: 100%;
        max-height: 14rem;
        border-radius: 1.25rem;
        margin-bottom: 1rem;
    }

    .name {
        margin-bottom: 0.5rem;
        text-transform: uppercase;
        color: var(--zumba-neon-green);
    }

    .description {
        color: var(--zumba-white);
        margin-bottom: 0;
    }
}
</style>
