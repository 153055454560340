<template>
    <Modal
        :show="show"
        show-close-button
        mobile-drawer
        close-on-mask
        @closed="$emit('closed')"
    >
        <h5
            :class="[isMobile() ? 'z-h5' : 'z-h4', 'header']"
        >
            {{ t.headerFSA }}
        </h5>
        <div class="f-inter instructions">
            <ol class="steps">
                <li class="z-b4 step">
                    <strong>{{ t.i1 }}</strong> {{ t.i1d }}
                </li>
                <li class="z-b4 step">
                    <strong>{{ t.i2 }}</strong> {{ t.i2d }}
                </li>
                <li class="z-b4 step">
                    <strong>{{ t.i3 }}</strong> {{ t.i3d }}
                    <a
                        :href="trueMedLink"
                        class="link"
                        target="_blank"
                    >
                        {{ t.thisForm }}
                        <ExternalLinkSvg />
                    </a>.
                </li>
                <li class="z-b4 step">
                    <strong>{{ t.i4 }}</strong> {{ t.i4d }}
                </li>
                <li class="z-b4 step">
                    <strong>{{ t.i5 }}</strong>
                </li>
            </ol>
            <div class="z-b4 inter faq">
                <strong class="z-b4 faq_text">{{ t.moreQuestions }}</strong>
                <a
                    :href="faqLink"
                    class="inter link"
                    target="_blank"
                >
                    {{ t.visitFAQ }}
                    <ExternalLinkSvg />
                </a>
            </div>
            <div class="buttons">
                <z-button
                    variety="optimus"
                    @click="$emit('closed')"
                >
                    {{ t.close }}
                </z-button>
            </div>
        </div>
    </Modal>
</template>

<script lang="ts">
const tDefaults = {
    headerFSA: 'FSA/HSA Post-Purchase Reimbursement Instructions',
    i1: 'Complete Your Purchase:',
    i1d: 'First, purchase your Zumba App subscription. We recommend choosing the annual plan for maximum savings and convenience, as it provides the best value and reduces the need for multiple submissions.',
    i2: 'Save Your Receipt:',
    i2d: 'Keep a copy of your purchase receipt as proof of payment.',
    i3: 'Complete TrueMed Questioner:',
    i3d: 'Obtain your Letter of Medical Necessity by filling out ',
    thisForm: 'this form',
    i4: 'Submit Documentation:',
    i4d: 'Upload your receipt for the Zumba App subscription and your TrueMed Letter of Medical Necessity (LMN).',
    i5: 'Complete the Reimbursement Form',
    moreQuestions: 'More questions?',
    visitFAQ: 'Visit our FAQ',
    close: 'Close',
};
</script>>
<script setup lang="ts">
import { PropType } from 'vue'
import Modal from "@components/Core/Modal.vue";
import { validator } from '@ts/Util/i18n'
import { isMobile } from "@ts/Util/responsiveness";
import ZButton from "@components/Core/ZButton.vue";
import ExternalLinkSvg from '@icons/ic-external-link.svg'

const props = defineProps({
    t: {
        type: Object,
        default: (): Object => tDefaults,
        validator: validator(tDefaults)
    },
    show: {
        type: Boolean,
        required: true,
    },
    trueMedLink: {
        type: String,
        required: true,
    },
    faqLink: {
        type: String,
        required: true,
    },
});

const emit = defineEmits(['closed'])
</script>

<style scoped>
.header {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.faq {
    margin-bottom: 1.5rem;
}
.faq_text {
    margin-right: 0.3125rem;
}
.steps .step {
    margin-bottom: 1rem;
    line-height: 1.25rem;
}
.instructions .link {
    display: inline-flex;
    gap: 0.32rem;
    align-items: center;
}
.instructions .link svg {
    width: 1rem;
    height: 1rem;
}
@media only screen and (min-width: 64em) {
    .buttons {
        display: flex;
        justify-content: center;
    }
}
</style>
