<template>
    <section class="section-join-free">
        <div class="container">
            <h3 class="header z-h3">
                <span class="line1">
                    {{ props.t.header.line1 }}
                </span>
                <span class="line2">
                    {{ props.t.header.line2 }}
                </span>
            </h3>

            
            <div class="qr">
                <slot name="qrcode">
                    <img
                        src="@images/AppSubscriptionLanding/JoinFree/qr.png"
                        alt="QR Code"
                    >
                </slot>
            </div>

            <p class="qr-text scan-text z-b2 f-inter">
                {{ props.t.scanText }}
            </p>

            <div class="stores">
                <a
                    id="ios_link"
                    :href="props.smartLink ? props.smartLink : props.t.appStoreLink"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <AppStore class="store" />
                </a>
                <a
                    id="andrd_link"
                    :href="props.smartLink ? props.smartLink : props.t.playStoreLink"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <PlayStore class="store" />
                </a>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import { PropType } from "vue";
import AppStore from "@images/AppSubscriptionLanding/JoinFree/AppStore.svg"
import PlayStore from "@images/AppSubscriptionLanding/JoinFree/PlayStore.svg"

const props = defineProps({
    t: {
        type: Object as PropType<{
            header: { line1: string, line2: string },
            scanText: string,
            appStoreLink: string,
            playStoreLink: string
        }>,
        default: () => ({})
    },
    smartLink: {
        type: String,
        default: ''
    }
})

</script>

<style lang="scss" scoped>
.section-join-free {
    background-color: var(--zumba-dark-green);
    background-image: url('@images/AppSubscriptionLanding/JoinFree/background-mobile.svg?external');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;

    .container {
        padding: 3.75rem 1.5rem;

        h3 {
            text-align: center;
            margin-bottom: 2rem;

            span {
                display: block;
            }

            .line1 {
                color: var(--zumba-white);
            }

            .line2 {
                color: var(--zumba-neon-green);
            }
        }

        .qr {
            display: none;
            text-align: center;
            margin-bottom: 2rem;

            img {
                width: 11.25rem;
                height: 11.25rem;
                padding: 0.75rem;
                border-radius: 1.5rem;
                background-color: var(--zumba-white);
            }
        }

        .scan-text {
            display: none;
            color: var(--zumba-white);
            text-align: center;
            margin-bottom: 2rem;
        }

        .stores {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .store {
                max-width: 12.5rem;
                width: 100%;
                height: 5.313rem;
            }
        }
    }
}

@media screen and (min-width: 48em) {
    .section-join-free {
        background-image: url('@images/AppSubscriptionLanding/JoinFree/background.svg?external');
    }
}

@media screen and (min-width: 64em) {
    .section-join-free {
        .container {
            padding: 6.25rem 7rem;

            h3 {
                font-family: Zandes-Medium;
                font-size: 3.6875rem;
                line-height: 4.0625rem;
                margin-bottom: 2rem;
            }

            .qr {
                display: block;
            }
            .scan-text {
                display: block;
            }

            .stores {
                display: none;
                flex-direction: row;
            }
        }
    }
}
</style>
