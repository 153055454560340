<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
    <div class="promo-banner">
        <a
            v-if="link"
            :href="link"
            :target="target"
            rel="noopener noreferrer"
        >
            <div
                class="promo-banner__content"
                v-html="content"
            />
        </a>
        <div
            v-else
            class="promo-banner__content"
            v-html="content"
        />
    </div>
</template>
<script setup lang="ts">
import { PropType } from 'vue'

const props = defineProps({
    content: {
        type: String,
        required: true,
    },
    link: {
        type: String,
        required: false,
        default: undefined,
    },
    target: {
        type: String as PropType<'_blank' | '_self'>,
        required: false,
        default: '_self',
    }
})

</script>

<style scoped>
.promo-banner {
    background: var(--zumba-light-purple, #A82BEB);
    color: var(--zumba-white, #FFF);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
}
.promo-banner a {
    color: var(--zumba-white, #FFF);
    text-decoration: none;
    margin: 0;
}
.promo-banner__content {
    padding: 5px 12px;
    line-height: 24px;
}
</style>
