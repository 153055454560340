<template>
    <div
        class="fitness-type-item"
        :style="{
            'background-image': `url(${fitnessType.backgroundUrl})`
        }"
    >
        <div class="overlay" />
        <p class="title z-h6">
            {{ fitnessType.name }}
        </p>
    </div>
</template>

<script lang="ts" setup>
import {PropType} from "vue";

export interface FitnessType {
    backgroundUrl: string,
    alt: string,
    name: string,
}

const props = defineProps({
    fitnessType: {
        type: Object as PropType<FitnessType>,
        required: true
    },
})
</script>

<style lang="scss">
.fitness-type-item {
    position: relative;
    display: inline-block;
    overflow: hidden;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 1.25rem;
    width: 11rem;
    height: 7.5rem;

    @media all and (max-width: 26rem) {
        width: 9rem;
        height: 6.5rem;
    }

    .title {
        color: var(--zumba-white);
        position: absolute;
        z-index: 1;
        bottom: 0;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 0.625rem;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.62) 80%),
        linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));
    }
}
</style>
