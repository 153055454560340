<template>
    <section className="trustpilot-reviews">
        <div class="reviews-container">
            <h1 class="title z-h1">
                {{ props.t.sectionTitle }}
            </h1>
            <div class="show-for-medium-down">
                <div class="carousel-container">
                    <CarouselShared
                        slide-selector=".reviews-item"
                        slider-selector="#reviews-slider"
                        :include-dot-buttons="true"
                        :include-direction-buttons="false"
                        :interval="7000"
                        :amount-of-slides="3"
                    >
                        <div
                            id="reviews-slider"
                            class="clearfix slides"
                        >
                            <div class="reviews-item">
                                <img src="@images/AppSubscriptionLanding/TrustPilotReviews/5stars.png">
                                <span class="description z-h5">“{{ props.t.review1 }}”</span>
                            </div>
                            <div class="reviews-item">
                                <img src="@images/AppSubscriptionLanding/TrustPilotReviews/4stars.png">
                                <span class="description z-h5">“{{ props.t.review2 }}”</span>
                            </div>
                            <div class="reviews-item">
                                <img src="@images/AppSubscriptionLanding/TrustPilotReviews/5stars.png">
                                <span class="description z-h5">"{{ props.t.review3 }}"</span>
                            </div>
                        </div>
                    </CarouselShared>
                </div>
            </div>
            <div class="reviews show-for-large-up">
                <div class="review">
                    <img src="@images/AppSubscriptionLanding/TrustPilotReviews/5stars.png">
                    <span class="description z-h5">“{{ props.t.review1 }}”</span>
                </div>
                <div class="review">
                    <img src="@images/AppSubscriptionLanding/TrustPilotReviews/4stars.png">
                    <span class="description z-h5">“{{ props.t.review2 }}”</span>
                </div>
                <div class="review">
                    <img src="@images/AppSubscriptionLanding/TrustPilotReviews/5stars.png">
                    <span class="description z-h5">"{{ props.t.review3 }}"</span>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import CarouselShared from "@components/Core/CarouselShared.vue";
import { PropType } from "vue";
import Trustpilot from "@images/AppSubscriptionLanding/TrustPilotReviews/Trustpilot.svg"

const props = defineProps({
    t: {
        type: Object as PropType<{ sectionTitle: string, review1: string, review2: string, review3: string }>,
        default: () => ({
            sectionTitle: "Music to our ears",
            review1: "I'm down 12lbs already and have never felt so motivated to workout. Who knew fitness could be so fun! Thank you Zumba.",
            review2: "Not sure what I love more… How fun the workouts are or that so many of my friends are commenting that I look in such great shape.",
            review3: "You can incorporate it easily in your daily schedule. The workouts are motivating, plus the instructors are fun and have easy-to-follow choreo.",
        })
    },
})

</script>

<style lang="scss">
.trustpilot-reviews {
    .carousel-container {
        .flex-control-nav {
            li {
                a {
                    background-color: rgba(255, 255, 255, 0.4);

                    &.flex-active-zumba {
                        background-color: rgba(255, 255, 255, 1);
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
p {
    margin-bottom: 0;
    font-family: inherit;
}

.trustpilot-reviews {
    background-image: url('@images/AppSubscriptionLanding/TrustPilotReviews/background.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 20px;
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;

    .reviews-container {
        max-width: 1216px;
        display: flex;
        gap: 20px;
        flex-direction: column;

        .title {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            align-self: stretch;
            color: var(--zumba-gray-800, #2C2D37);
            text-align: center;
            margin: 0;
            font-size: 36px;
            font-style: normal;
            font-weight: 900;
            line-height: 54px;
            letter-spacing: -0.96px;
            text-transform: uppercase;
            font-family: Zandes-Bold;
        }

        .trustpilot-logo {
            align-self: center;
        }
    }
}

#reviews-slider {
    position: relative;
    width: 100%;
    padding-bottom: 20px;

    .reviews-item {
        display: flex;
        padding: 10px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.10);
        padding: 40px;
        gap: 24px;
        min-height: 440px;

        .description {
            color: var(--zumba-gray-800, #2C2D37);
            font-feature-settings: 'clig' off, 'liga' off;
            letter-spacing: -0.56px;
            flex: 1;
        }

        .author {
            color: var(--zumba-gray-800, #2C2D37);
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
        }
    }

}

@media screen and (min-width: 768px) {
    #reviews-slider {
        .reviews-item {
            min-height: 280px;
        }
    }
}

@media screen and (min-width: 1024px) {
    .trustpilot-reviews {
        padding: 80px 112px 60px 112px;

        .reviews-container {
            .title {
                font-size: 48px;
            }

            .reviews {
                display: flex;
                align-items: flex-start;
                gap: 32px;
                align-self: stretch;

                .review {
                    display: flex;
                    padding: 40px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 24px;
                    flex: 1 0 0;
                    align-self: stretch;
                    border-radius: 20px;
                    background: rgba(255, 255, 255, 0.10);

                    .description {
                        color: var(--zumba-gray-800, #2C2D37);
                        letter-spacing: -0.56px;
                        flex: 1;
                    }

                    .author {
                        color: var(--zumba-gray-800, #2C2D37);

                        /* Paragraph/Large/Medium */
                        font-family: Inter;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 28px;
                        /* 155.556% */
                    }
                }
            }
        }
    }
}
</style>
