<template>
    <section class="section-faqs">
        <div class="container">
            <h3 class="title z-h3">
                {{ props.header.line1 }}
                <br>
                {{ props.header.line2 }}
            </h3>

            <div class="faqs">
                <div
                    v-for="(faq, index) in props.faqs"
                    :key="index"
                    class="faq"
                >
                    <div
                        class="question"
                        @click="toggleItem(index)"
                    >
                        <h5 class="z-h5">
                            {{ faq.question }}
                        </h5>

                        <button @click="(event) => { toggleItem(index); event.stopPropagation(); }">
                            <svg
                                v-if="openedItems.includes(index)"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M15 13L10 8L5 13"
                                    stroke="#737373"
                                    stroke-width="1.67"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <svg
                                v-else
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M15 8L10 13L5 8"
                                    stroke="#737373"
                                    stroke-width="1.67"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                    <!-- eslint-disable vue/no-v-html-->
                    <div
                        v-show="openedItems.includes(index)"
                        class="answer"
                        v-html="faq.answer"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import { PropType, ref } from 'vue';
const openedItems = ref<Array<number>>([]);

const toggleItem = (index: number) => {
    if (openedItems.value.includes(index)) {
        openedItems.value = openedItems.value.filter((i) => i !== index);
    } else {
        openedItems.value = [...openedItems.value, index];
    }
};

const props = defineProps({
    header: {
        type: Object as PropType<{ line1: string, line2: string }>,
        required: true
    },
    faqs: {
        type: Array<{ question: string, answer: string }>,
        required: true
    }
})
</script>

<style lang="scss" scoped>
p {
    margin: 0;
}

.section-faqs {
    padding: 2.5rem 1.5rem;

    .container {
        margin: 0 auto;

        .title {
            text-align: center;
            text-transform: uppercase;
            color: var(--zumba-gray-800);
            margin: 0 0 2.5rem;
        }

        .faqs {
            .faq {
                border-bottom: 0.063rem solid var(--zumba-gray-200);
                padding: 1rem;
                position: relative;

                &:last-of-type {
                    border-bottom: unset;
                }

                .question {
                    cursor: pointer;
                    padding-right: 1.25rem;

                    h5 {
                        margin-right: 1rem;
                        font-family: Zandes-Extra-Bold;
                        color: var(--zumba-gray-800);
                    }

                    button {
                        position: absolute;
                        right: 1rem;
                        top: 1.25rem;
                        background: none;
                        border: none;
                        padding: unset;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .answer {
                    margin-top: 1rem;

                    &:deep(*) {
                        color: var(--zumba-gray-800);
                        font-size: 1rem !important;
                        line-height: 1.375rem !important;
                        font-family: Inter, "Zandes Web", Helvetica, Arial, sans-serif;
                    }

                    &:deep(*:last-child) {
                        margin-bottom: 0;
                    }
                }
            }

        }
    }
}

@media screen and (min-width: 64em) {
    .section-faqs {
        padding: 5rem 0;

        .container {
            max-width: 65rem;

            .title {
                font-size: 3.125rem;
                line-height: 3.375rem;
            }

            .faqs {
                .faq {
                    .answer {
                        &:deep(*) {
                            font-size: 1.125rem !important;
                            line-height: 1.625rem !important;
                        }
                    }
                }
            }
        }
    }
}
</style>
